import React, { Component } from 'react'
import { connect } from 'react-redux'

interface Iprops{
    valueS:any
}

interface Istate{
        pp:String
}

class   Context  extends  Component<Iprops,Istate>{   //先在这里挂载 Iprops 才可以可以

        constructor(props:Iprops){
                super(props);
        }


    render(){

        return(
            <div>
            
            <div>鑫青蒙管理后台</div>

            <div>context</div>
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
        valueS:state
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Context)
