// import { ModifyAction } from '../action/index';

// import { DECREMENT, INCREMENT } from '../const';


// // 处理并返回 state 
// export default (state = 0, action: ModifyAction): number => {
//     switch (action.type) {
//       case INCREMENT:
//         return state + 1
//       case DECREMENT:
//         return state - 1
//       default:
//         return state
//     }
// }

import { ACTION_TYPE, INCREMENT_COUNTER, DECREMENT_COUNTER ,TODOLIST ,BARCHANGE ,ROUTECHANGE,PPTLINK} from "../types/index";

interface CountState {
    count: String,
    storeCount:any,
    barCurrent:String,
    routeLand:string,
    pptName:string
}
export const initialState: CountState = {
    count: '00000',
    storeCount:'redux中的数据',
    barCurrent:'首页',
    routeLand:'首页',
    pptName:''
}
export function countReducer(state = initialState, action: ACTION_TYPE):CountState {
  
  
  console.log('运行reducer')


    switch (action.type) {
        case INCREMENT_COUNTER:
        console.log('增加reducer')
            return { ...state, count: '123231'};
        case DECREMENT_COUNTER:
         console.log('减少reducertodo',action.actionData)
            return {
             ...state, 
             count:action.actionData
             };
       case TODOLIST:
        console.log('增加reducer', action.todoListData)
        return { ...state,
         count: action.todoListData};

        case BARCHANGE:
        console.log('hoc',action.barTitle)   //action.barTitle  这里是传递的参数
        return { ...state,
             barCurrent: action.barTitle
             
         };
         case ROUTECHANGE:
           console.log('恶趣味请问请问请问请问请问请问请问请问我企鹅我去路由名字',action.rlData)
           return {
             ...state,
             routeLand: action.rlData
           };
           case PPTLINK:
           console.log('ppt名字ppt名字ppt名字ppt名字',action.pptData)
           return {
             ...state,
             pptName: action.pptData
           }

        default:
            return state;
    }
}

