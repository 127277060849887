import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';

 import { countReducer, initialState } from "./store/reducer/index";

 import { countReducer1, initialState1 } from "./store copy/reducer/index2";
 
const reducers = combineReducers({
    countReducer,
    countReducer1
});
//引入组合后的
export type reducerType = ReturnType<typeof reducers>;//获取合并后的reducers类型

//第一个参数就是组合后的reducers 第二个参数就是默认值 第三个就是异步的thunk那些


const store =  createStore(reducers, {     //合并多个 reducer
    countReducer:initialState,
   countReducer1:initialState1
}, applyMiddleware(thunk));

export default store