// import {DECREMENT, DECREMENT_TYPE, INCREMENT, INCREMENT_TYPE} from '../const'

// export interface IINCREMENTAction {
//   type: INCREMENT_TYPE;
// }

// export interface IDECREMENTAction {
//   type: DECREMENT_TYPE;
// }

// // 定义 modifyAction 类型，包含 IINCREMENTAction 和 IDECREMENTAction 接口类型
// export type ModifyAction = IINCREMENTAction | IDECREMENTAction;


// // 增加 state 次数的方法
// export const increment = (): IINCREMENTAction => ({
//   type: INCREMENT,
// })

// // 减少 state 次数的方法
// export const decrement = (): IDECREMENTAction => ({
//   type: DECREMENT
// })


//   !!!!!！！！   这里要定义 types 里面的 type  和   action 
import { 
INCREMENT_COUNTER, 
DECREMENT_COUNTER,
TODOLIST,BARCHANGE,
INCREMENT_ACTION_TYPE,
DECREMENT_ACTION_TYPE ,
TODOLIST_ACTION_TYPE,
BARCHANGE_ACTION_TYPE,

ROUTECHANGE, //type
ROUTECHANGE_ACTION_TYPE, //action

PPTLINK,
PPTLINK_ACTION_TYPE
} from '../types/index';
//   !!!!!！！！   这里要定义 types 里面的 type  和   action 
import { Dispatch } from 'redux';

const incrementCount = (number: number): INCREMENT_ACTION_TYPE => ({ type: INCREMENT_COUNTER, number }) 

//异步action
export const asyncIncrementCount = (number: number) => (dispatch: Dispatch<INCREMENT_ACTION_TYPE>) => {

    setTimeout(() => dispatch(incrementCount(number)), 1000);
}

export const decrementCount = (actionData:String): DECREMENT_ACTION_TYPE => ( { type: DECREMENT_COUNTER, actionData} );
//导出一个action    是一个函数  参数来一个类型   对应types 里面的 interface actions     ()=>{  { type: } }  对应action里面的type


export const todolistAction = (todoListData:any): TODOLIST_ACTION_TYPE => ({ type: TODOLIST ,todoListData   })
                                                // 这里对应的是types里面定义的nationtypes  

export const barSelectAction = (barTitle:any): BARCHANGE_ACTION_TYPE => ({ type: BARCHANGE ,barTitle   })
                                                                                //BARCHANGE 这里是types定义的

export  const routelandAction =(rlData:string):ROUTECHANGE_ACTION_TYPE=>({type:ROUTECHANGE,rlData})         

export  const pptLinkAction =(pptData:string):PPTLINK_ACTION_TYPE=>({type:PPTLINK,pptData})         