import React from 'react';


import { Spin }  from  'antd'

const loading = () => {
    return (

        // const Mystyle={
        //     text-align: center,
        //     background: rgba(0, 0, 0, 0.05),
        //     border-radius: 4px,
        //     margin-bottom: 20px;
        //     padding: 30px 50px,
        //     margin: 20px 0;
        //   }
        <div>
            <div className="example" style={{marginTop:'40vh' }} >
                <Spin />
            </div>
        </div>
    );
}

export default loading;
