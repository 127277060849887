// 函数式组件

import React, { Component } from 'react'
import { connect } from 'react-redux'

type IProps = {
    onPress: () => void;
    iconStyle?: any;
    url: string,
};
type Istate = {
    iurl: string,
};

// 泛型类型“FC”需要介于 0 和 1 类型参数之间
const TouchIcon: React.FC<IProps> = (props: IProps) => {
    return (
      <div>
        <div>函数式组件</div>
          <div onClick={props.onPress}>

        <div>函数式组件--写页面 写逻辑</div>
            <p>{props.url}</p>
        </div>
      </div>
    );
};

export default TouchIcon
