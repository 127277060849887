import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'

import { Button, message } from 'antd';

//本地后台服务
//axios.defaults.baseURL = "http://192.168.0.111:8202"

//线上正式后台服务
// axios.defaults.baseURL="https://xqm.pccedu.cn/qjyk-xqm"


 axios.defaults.baseURL="https://xqmcm.com/qjyk-xqm"

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(

  config => {

    //config.headers['Authorization'] = "Bearer " + localStorage.getItem('token')
   // config.headers["Content-Type"]= "application/x-www-form-urlencoded";

    return config
  },
  error => {
    // do something with request error
    console.log(error,'error') // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    console.log(response)
    if (!res) {
      return
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      if (!res) {

        return

      } else {
        // Message({
        //   message: res.message || 'Error',
        //   type: 'error',
        //   duration: 5 * 1000
        // })


        //  const key = 'updatable';
        // message.loading({ content: 'Loading...', key });
        //  setTimeout(() => {
        //    message.success({ content: res.message , key, duration: 2 });
        //  }, 1000);

        message.error({
          content: '错误',
          className: 'custom-class',
          style: {
            marginTop: '20vh',
          },
        });

      }


      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('你已经被登出，请重新登录', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
         return res
    }
  },
  error => {

    console.log(error,'error')
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    setTimeout(() => {
      message.error({ content: error.message, key, duration: 2 });
    }, 1000);

    return Promise.reject(error)

  }
)

export default service
