
import { INCREMENT_COUNTER,
          DECREMENT_COUNTER,TODOLIST, 
        INCREMENT_ACTION_TYPE,
        DECREMENT_ACTION_TYPE ,
          TODOLIST_ACTION_TYPE ,
          NEXR ,  //types 类型
          NEXR_ACTION_TYPE ,   // action  类型

          } from '../types/index';

import { Dispatch } from 'redux';

const incrementCount = (number: number): INCREMENT_ACTION_TYPE => ({ type: INCREMENT_COUNTER, number }) 

//异步action
export const asyncIncrementCount = (number: number) => (dispatch: Dispatch<INCREMENT_ACTION_TYPE>) => {

    setTimeout(() => dispatch(incrementCount(number)), 1000);
}

export const decrementCount = (actionData:String): DECREMENT_ACTION_TYPE => ( { type: DECREMENT_COUNTER, actionData} );
//导出一个action    是一个函数  参数来一个类型  actionData是types里面的action对应的参数名   对应types 里面的 interface actions  
                    //   ()=>{  { type: 这里的type对应的就是types 里面定义的参数（export const DECREMENT_COUNTER = "DECREMENT_COUNTER";）  } }


//定义 action 方法
export const nexActionM = (nexData:String):NEXR_ACTION_TYPE => ( { type: NEXR , nexData })

export const todolistAction = (todoListData:any): TODOLIST_ACTION_TYPE => ({ type: TODOLIST ,todoListData   })
                                                // 这里对应的是types里面定义的nationtypes  