
import { ACTION_TYPE, INCREMENT_COUNTER, DECREMENT_COUNTER ,TODOLIST ,NEXR} from "../types/index";

interface CountState {
  //  count: String,
   // storeCount:any,
    count2:any,
    storeCount2:any
}
export const initialState1: CountState = {
    count2: '新的reducers',
    storeCount2:'redux中的数据'
}
export function countReducer1(state = initialState1, action: ACTION_TYPE):CountState {
  
  
  console.log('运行reducer')


    switch (action.type) {
        case INCREMENT_COUNTER:
        console.log('增加reducer')
            return { ...state, count2: '123231'};
        case DECREMENT_COUNTER:
         console.log('减少reducertodo',action.actionData)
            return {
             ...state, 
             count2:action.actionData
             };   

        case NEXR:  // 这里的 action.type  就是types 里面定义的参数  也是types中 action的type
         console.log('减少reducertodo',action.nexData)
            return {
             ...state, 
             count2:action.nexData
       };
       case TODOLIST:
        console.log('增加reducer', action.todoListData)
        const a=action.todoListData
         return { ...state, count2: a};
        default:
            return state;
    }
}

