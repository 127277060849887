// // 定义 State 结构类型
// export type StoreState = number;



export const INCREMENT_COUNTER = "INCREMENT_COUNTER";

export type INCREMENT_COUNTER = typeof INCREMENT_COUNTER;

export const DECREMENT_COUNTER = "DECREMENT_COUNTER";

export type DECREMENT_COUNTER = typeof DECREMENT_COUNTER; // 声明类型


export const TODOLIST='TODOLIST' ;

export type  TODOLIST =typeof TODOLIST;


  export const NEXR ='NEXR'
export type NEXR= typeof NEXR


// action类型
export interface INCREMENT_ACTION_TYPE{
    type:INCREMENT_COUNTER;
    number:number;
}
export interface DECREMENT_ACTION_TYPE{
    type:DECREMENT_COUNTER;
    actionData:String;     //  定义参数的值 和 类型
}

export interface TODOLIST_ACTION_TYPE{
    type:TODOLIST,
    todoListData?:any
}
//定义action
export interface NEXR_ACTION_TYPE{
     type:NEXR,   
     nexData:any
}


export type ACTION_TYPE = INCREMENT_ACTION_TYPE | DECREMENT_ACTION_TYPE |TODOLIST_ACTION_TYPE|NEXR_ACTION_TYPE ;       //reducer的action接收的类型


