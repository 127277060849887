// // 定义 State 结构类型
// export type StoreState = number;

import { type } from "os";



export const INCREMENT_COUNTER = "INCREMENT_COUNTER";

export type INCREMENT_COUNTER = typeof INCREMENT_COUNTER;

export const DECREMENT_COUNTER = "DECREMENT_COUNTER";    //这里是为了给action定义type值

export type DECREMENT_COUNTER = typeof DECREMENT_COUNTER; // 声明类型


export const TODOLIST='TODOLIST' ;

export type  TODOLIST =typeof TODOLIST;



// 改变bar-title
export const BARCHANGE='BARCHANGE' ;

export type  BARCHANGE =typeof BARCHANGE;

// 改变 所在页面地址

export const ROUTECHANGE='ROUTECHANGE' ;

export type  ROUTECHANGE = typeof ROUTECHANGE;

// 改变 ppt名字

export const PPTLINK='PPTLINK' ;

export type  PPTLINK = typeof PPTLINK;


  

// action类型
export interface INCREMENT_ACTION_TYPE{
    type:INCREMENT_COUNTER;
    number:number;
}
export interface DECREMENT_ACTION_TYPE{
    type:DECREMENT_COUNTER;
    actionData:String;     //  定义参数的值 和 类型
}

export interface TODOLIST_ACTION_TYPE{
    type:TODOLIST,
    todoListData?:any
}


// 改变bar-title-action
export interface BARCHANGE_ACTION_TYPE{
    type:BARCHANGE,
    barTitle?:any
} 
// 改变 所在页面地址  action

export interface ROUTECHANGE_ACTION_TYPE{         // 这边的接口 需要导出
    type:ROUTECHANGE,  // 这里是type定义的 数据

    rlData?:string   //传递的数据参数
}

// ppt名字action
export interface PPTLINK_ACTION_TYPE{
    type:PPTLINK;
    pptData?:string;
}





export type ACTION_TYPE = INCREMENT_ACTION_TYPE | DECREMENT_ACTION_TYPE |TODOLIST_ACTION_TYPE|BARCHANGE_ACTION_TYPE |ROUTECHANGE_ACTION_TYPE|PPTLINK_ACTION_TYPE ;       //reducer的action接收的类型





