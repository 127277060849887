import React ,{Component} from 'react';
import RouterMap  from  './route/routerTs'

import './style/home.less'
class App extends Component {

   constructor(props:any){
     super(props);

   }
  render() {
    return (
      <div className='T' style={{ width: '100%',display: 'flex', justifyContent: 'center',cursor: 'pointer' }}  > 
       <RouterMap></RouterMap>
      </div>
    );
  }  //render


}



export default App;
