import React, { Component } from 'react'
import { connect } from 'react-redux'


import { testData, jsonTestData, pythonD ,pythonFamily} from '../utils/api'



export class storets extends Component {





    componentDidMount() {


        const param = { id:'第二种' }

        // pythonD(param).then((res: any) => {
        //     console.log(res, 'resresresresres')
        // })
        // .catch(err => {
        //     console.log(err, 'error')
        // })

        pythonFamily(param).then((res: any) => {
            console.log(res, 'resresresresres')
        })
        .catch(err => {
            console.log(err, 'error')
        })


    }

    render() {
        return (
            <div>
                <div>测试；python</div>
                <div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(storets)
