// import HttpClient from './http'

// //let baseUrl='https://qjyke.com/v0.2/whzc'

// let baseUrl='http://192.168.0.114:8800'

// export function GetServeData(param) {   // 这里定义方法 参数 url 
//     return HttpClient.get(`../static/data/service.json`, {param})
// }


// export function testData(param) {   // 这里定义方法 参数 url   //不用包对象
//     return HttpClient.post(`${baseUrl}+/ttc/recruit/resume/list`, param)
// }
// export default {
//     GetServeData ,testData
// }

import request from './request'

// let postData = this.$qs.stringify({
//     key1:value1,
//     key2:value2,
// });


export function getInfo(params) {
  //console.log(params)
  return request({
    url: `/ttc/course/information/list`,
    method: 'post',
    data:params
  })
}


export function testData(params) {
  console.log(params)
  return request({
    url: `/ttc/auth/organization/list`,
    method: 'get',
    data:params
  })
}

export function jsonTestData(params) {
  console.log(params)
  return request({
    url: `http://192.168.0.116:8888/`,
    method: 'get',
    data:params
  })
}

// couserDeatiled   详情
export function couserDeatiled(params) {
  console.log(params)
  return request({
    url: `/ttc/course/activity/list`,
    method: 'post',
    data:params
  })
}


export function pythonD(params) {
  return request({
    url: `/cal`,
    method: 'post',
    data:params
  })
}

export function pythonFamily(params) {
  return request({
    url: `/family`,
    method: 'post',
    data:params
  })
}



