import React, { Component, createRef, MouseEvent } from 'react'


import Context from '../constants/context'
interface Iprops {    //这里写几个就必须要传几个
    listP: String          //  [propsName:string]:any  // 可以定义多个      work?:string   这里可传可不传
    //  childM:any    //传递的方法 也要定义类型  onClick (event: MouseEvent<HTMLDivElement>): void
    onClick(event: MouseEvent<HTMLDivElement>): void,
}

interface Istate {
    listName: String
    addData: any,

}




class componentName extends Component<Iprops, Istate> {


    constructor(props: Iprops) {
        super(props);

        // this.state={
        //     addData:[]
        // }
    }

    public readonly state: Readonly<Istate> = {    //申明只读属性     (这里的state  直接定义  然后在render 里面 this.state  获取)
        listName: '列表名',
        addData: [
            {
                a: 1,
                b: 1
            },
            {
                a: 12,
                b: 12
            }, {
                a: 13,
                b: 13
            }

        ]
    }

    private inputRef = React.createRef<HTMLInputElement>()


    componentDidMount() {

        console.log(this.state.addData)

        console.log(this.refs.test)

        let buttons = this.refs.test

        let btn = buttons as HTMLElement   // 一个元素 如果是那么要给类型

        btn.style.backgroundColor = 'red'


        console.log(this.inputRef.current)
        this.inputRef.current.focus()
    }


    render() {
        return (
            <div>
                <div>列表{this.state.listName}</div>
                <div>{this.props.listP}----</div>
                <div>
                    {
                        this.state.addData.map((item, index) => {
                            return (
                                <div>{item.a}</div>
                            )
                        })
                    }
                </div>

                <div ref="test" >ref</div>

                <input ref={this.inputRef} type="text" />

                // <div onClick={this.refDom.bind(this, Element, '123', '345')} >点击事件</div>

                 <div onClick={  (e)=>this.delFolder1("abc",e) } >点击事件11</div>


                <Context></Context>


            </div>
        )
    }

    refDom = (e ) => {
        console.log(e)

        return (eee) => {

            console.log(eee);


        } 
        
    }

    delFolder1 = (name,e)=>{
                console.log(name ,e)
      }

    // this.refDom()


    delFolder = (e) => {

        console.log(e)

    }
}

const mapStateToProps = (state) => {

    const { markVisible, loadProgress } = state;
    return {
        valueS: state   //使得 页面可以通过props访问 redux
    }
}

export function mapDispatchToProps(dispath: any) {

    return {

        // derM:()=>{  console.log(999); dispath(actions.decrementCount) }
        //    derM:()=> dispath(actions.decrementCount('ni')),
        //    todoM:()=>dispath(actions.todolistAction(1) )       // 在这里定义  就要在 render 里面 实现

    }
}

export default componentName