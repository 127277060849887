import React, { Component } from 'react';

//import { connect ,Dispatch } from 'react-redux'
import { connect  } from 'react-redux'
import * as actions from  '../redux/store/action/index'


import store from  '../redux/index' ;

interface Iprops{
  //[propsName:string]:any
    count: number,
    storeCount:any,
    valueS:any,  
    derM?:()=>void,
    todoM?:()=>void     // 这里有了 那么下面就得定义      const {count , storeCount ,derM ,todoM }=this.props   //把props中的数据读取出来

}

interface IState{
        rnumber:any,
        rtext:String
}


class redux extends Component<Iprops,IState>{

        constructor(props:Iprops){
              super(props)
        }

        public  readonly state:Readonly<IState>={
                  rnumber:'12312312312',
                  rtext:'String'
        }

        componentDidMount(){
        
             console.log(this.props)
        }


        

    render() {

    //   <div>{this.props.value.countReducer.storeCount}</div>

           const {count , storeCount ,derM ,todoM }=this.props   //把props中的数据读取出来 ( 必须的)

          // const { asyncIncrementCount } = actions
        return (
            <div>
                        <div>react-redux</div>
                        <div>{this.state.rnumber}</div>
                         <div>redux中的数据{this.props.valueS.countReducer.storeCount}-----{this.props.valueS.countReducer.count}</div>
                         <h1 onClick={this.changRedux1.bind(this,1)} >改变</h1>
                         <h2 onClick={derM}>改变1</h2>
                          <h2 onClick={todoM}>改变1</h2>

                          <div> 多个reducers </div>

                          <div>新的redercer的数据------{this.props.valueS.countReducer1.count2}</div>
                          <div>更改新的redercer的数据</div>
                      
            </div>
        );
    }


    changRedux1=(e)=>{

            console.log(123321312)

           // this.state.rnumber=111

           this.setState(()=>{
                return{

                      rnumber: this.state.rnumber+'dddd'
                    }
           },()=>{
                  console.log('方法后面接着一个方法')
           })

            //   this.props.derM
    }

}

const mapStateToProps = (state) => ({
            valueS:state   //使得 页面可以通过props访问 redux
})

export  function  mapDispatchToProps(dispath:any){
  
    return{

            // derM:()=>{  console.log(999); dispath(actions.decrementCount) }
               derM:()=> dispath(actions.decrementCount('ni')),
               todoM:()=>dispath(actions.todolistAction(1) ) // ！！！！！！在这里Iprops定义  就要在 render 里面 实现

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(redux)

// export default redux


// import React, { Component } from 'react'
// import { connect } from 'react-redux'

// export const redux = () => {
//     return (
//         <div>
//                     <div>react-redux</div>
//         </div>  
//     )
// }

// const mapStateToProps = (state) => ({
    
// })

// const mapDispatchToProps = {
    
// }

// export default connect(mapStateToProps, mapDispatchToProps)(redux)
