import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';   //路由安装包
import App from '../App'

//路由懒加载
import Loadable from 'react-loadable';

import loading from '../components/loading'

import tsOne from '../constants/redux'
import tsOne1 from '../constants/redux1'
import refref from '../constants/ref'

import listlist from '../constants/pythonTest'
import list from '../constants/list'

import BackState from '../backstage/index'



// import Home from '../components/home'
//import CourseList from '../components/course/courseList'   //课程列表


//import CourseDetailed from '../components/course/courseDetailed'   //课程详情


const Home=Loadable({    //课程列表
    loader: () => import('../components/home'),
    loading: loading
})

const CourseDetailed=Loadable({    //园所服务列表
    loader: () => import('../components/course/courseDetailed'),
    loading: loading
})

const CourseList=Loadable({    //课程列表
    loader: () => import('../components/course/courseList'),
    loading: loading
})


const YuanList = Loadable({    //园所服务列表
    loader: () => import('.././components/service/yuanList'),
    loading: loading
})
const YuanDetailed = Loadable({    //园所服务列表
    loader: () => import('.././components/service/yuanDetailed'),
    loading: loading
})

const FamilyList = Loadable({    //家庭服务列表
    loader: () => import('.././components/service/famailyList'),
    loading: loading
})
const FirendList = Loadable({    //伙伴服务列表
    loader: () => import('.././components/service/firendList'),
    loading: loading
})


const ProduceList = Loadable({    //最新产品册---列表
    loader: () => import('.././components/produce/produceList'),
    loading: loading
})

const ProgrammeList = Loadable({    //最新方案服务列表
    loader: () => import('.././components/produce/programme'),
    loading: loading
})

const ProgrammeDetailed = Loadable({    //最新方案服务列表
    loader: () => import('.././components/produce/programmeDetailed'),
    loading: loading
})



const InnovationList = Loadable({    //最新环创方案服务列表
    loader: () => import('.././components/produce/innovation'),
    loading: loading
})


const TakeProduceList = Loadable({    //集采中心---产品---列表
    loader: () => import('.././components/take/takeProduceList'),
    loading: loading
})

const TakeProduceDetailed = Loadable({    //集采中心---产品详情---列表
    loader: () => import('.././components/take/TakeProduceDetailed'),
    loading: loading
})


const TakeBrandList = Loadable({    //集采中心---品牌中心---列表
    loader: () => import('.././components/take/takeBrandList'),
    loading: loading
})

const Information = Loadable({    //资讯列表-
    loader: () => import('.././components/information/information'),
    loading: loading
})

const Train = Loadable({    //canping-
    loader: () => import('.././components/produce/train'),
    loading: loading
})

const Cultrue = Loadable({    //文化传媒-
    loader: () => import('.././components/cultrue'),
    loading: loading
})

const router = class RouterMap extends React.Component {

    public render() {

        return (

            <Router>
                <Switch>

                    <Route exact path="/" component={Home} />

                    <Route path="/tsOne" component={tsOne} />
                    <Route path="/refref" component={refref} />
                    
                    <Route path="/list" component={list} />
                    <Route path="/listlist" component={listlist} />
                    <Route path="/tsOne1" component={tsOne1} />
                    <Route path="/backState" component={BackState} />

                    <Route path="/courseList" component={CourseList} />

                    <Route path="/courseDetailed" component={CourseDetailed} />

                    <Route path="/yuanList" component={YuanList} />
                    
                    <Route path="/yuanDetailed" component={YuanDetailed} />
                    <Route path="/familyList" component={FamilyList} />
                    <Route path="/firendList" component={FirendList} />

                    <Route path="/produceList" component={ProduceList} />
                  {
                    <Route path="/programmeList" component={ProgrammeList} />
                  }
                    <Route path="/programmeDetailed" component={ProgrammeDetailed} />
                    
                    <Route path="/innovationList" component={InnovationList} />


                    <Route path="/takeProduceList" component={TakeProduceList} />
                    <Route path="/takeProduceDetailed" component={TakeProduceDetailed} />
                    
                    <Route path="/takeBrandList" component={TakeBrandList} />

                        <Route path="/information" component={Information} />

                    <Route path="/train" component={Train} />

                    <Route path="/cultrue" component={Cultrue} />

                        {
                    //     <Route path="/information" component={Information} />

                    // <Route path="/train" component={Train} />

                    // <Route path="/cultrue" component={Cultrue} />
                        
                        }

                    

                     
                     







                    {/* <Redirect to='/404' > </Redirect>  */}
                    {/* 啥也没匹配到最后生成404 */}

                    {/* {
                routes.map((item,index)=>{
                   
                   return(
                    <Route key={index} exact path={item.path} component={item.component}   />
                   )
                })
            } */}

                </Switch>
            </Router>

        )
    }
    // return (
    //     <div>

    //     </div>
    // );
}

export default router;
