import React from 'react';
import ReactDOM from 'react-dom';
import Start from './satrt';

import store from  './redux/index' ;

import { Provider } from 'react-redux';

import { KeepAlive }  from 'react-keep-alive'

//axios
// import axios from 'axios' ;
// React.Component.prototype.$axios=axios   //  使用的话 是 this.$axios (最后面的axios  对应上一句 import 后面的axios)


var Tiff = require('tiff.js');
var fs = require('fs');

//  var filename = process.argv[2];
//  console.log(filename)
// var input = fs.readFileSync(filename);
// var image = new Tiff({ buffer: input });
// console.log(filename + ': width = ' + image.width() + ', height = ' + image.height());

ReactDOM.render(
  <Provider store={ store }>
   
      <Start/> 

    </Provider>,
    //  <Start/> ,
  document.getElementById('root') 
);
