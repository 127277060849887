import React, { Component } from 'react'
import { connect } from 'react-redux'


// import '../style/index.less'

import img from '../style/img/鄂A123456.jpg'

import * as  actions from '../redux/store copy/action/index'



import { Button } from 'antd';
import { Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface Iprops {
  //[propsName:string]:any
  // count: number,
  // storeCount:any,
  valueS: any,
  // derM?:()=>void,
  // todoM?:()=>void     // 这里有了 那么下面就得定义      const {count , storeCount ,derM ,todoM }=this.props   //把props中的数据读取出来
  nexM?: () => void
}

interface IState {
  // rnumber:any,
  // rtext:String
}

class redux1 extends Component<Iprops, IState>{


  constructor(props: Iprops) {
    super(props)
  }


  componentDidMount() {
    console.log(this.props)

    // this.props.router.setRouteLeaveHook(this.props.route, (nextLocation) => {
    //   if (nextLocation.pathname.indexOf('wxConcernSuccess') >= 0) {
    //     console.log('我离开了')
    //   }
    // })

  }



  render() {

    const { nexM } = this.props

    return (
      <div>
        <h1>新的reducer1</h1>
        <div>redux中的数据{this.props.valueS.countReducer.storeCount}-----{this.props.valueS.countReducer.count}</div>

        <div>新的redercer的数据------{this.props.valueS.countReducer1.count2}</div>

  <Input size="large" placeholder="large size" prefix={<UserOutlined />} />
   

           <Button  onClick={nexM}  type="primary">新的reducer-----新的方法</Button>
            
            <Button type="primary">Button</Button>


            <div onClick={this.goAdmin.bind(this)} >跳转</div>

        <img src={img} alt="" />
      </div>
    )


  }

     public goAdmin(){
     
          window.open('about:blank').location.href="http://localhost:3000/svnweb/index.html";
     }

}

const mapStateToProps = (state) => ({
  valueS: state
})

export function mapDispatchToProps(dispatch: any) {
  //1:  先更改 这个成方法
  // 2: 定义 和 引入 actions 后开始定义方法   -----这里定义的方法要在Iprops里面去定义
  return {

    nexM: () => { dispatch(actions.nexActionM('合并新的reducer的参数')) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(redux1)
